import React, { useState } from 'react'
import { Button, Form, Modal } from 'react-bootstrap';
import { PartnerUpdate } from '../../types/partners/PartnerUpdate';
import partnerService from '../../api/partner.api';
import ReactInputMask from 'react-input-mask';
import { AxiosError } from 'axios';

type Props = {
    className: string
    update: () => void
}

const Create = (props: Props) => {

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [error, setError]  = useState<string>('')

    const [formValues, setFormValue] = useState<PartnerUpdate>(new PartnerUpdate());

    function handleForm(e: React.FormEvent): void {
        e.preventDefault();
        setError('')
        partnerService.create(formValues).then((response) => {
            handleClose();
            props.update();
        }).catch((error:AxiosError) => {
            if(error.response?.status === 409) {
                setError("Компания с таким БИН уже существует")
            }
        })
    }

    return <>
        <Button variant="primary" onClick={handleShow} className={props.className}>
            Ручная регистрация компании
        </Button>

        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Добавление компании</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleForm}>

                    <Form.Group controlId='bin' className='mb-3'>
                        <Form.Label>*БИН</Form.Label>
                        <Form.Control value={formValues.bin}
                        as={ReactInputMask}
                        mask="999999999999"
                        required onChange={(e)=>setFormValue((prev)=>({...prev,bin:e.target.value}))} />
                    </Form.Group>

                    <Form.Group controlId='lawName' className='mb-3'>
                        <Form.Label>*Юридическое имя</Form.Label>
                        <Form.Control required value={formValues?.lawName} onChange={e => setFormValue((prev) => {
                            return { ...prev, lawName: e.target.value }
                        })} />
                    </Form.Group>

                    <Form.Group controlId='name' className='mb-3'>
                        <Form.Label>*Отображаемое в системе имя</Form.Label>
                        <Form.Control required value={formValues?.name} onChange={e => setFormValue((prev) => {
                            return { ...prev, name: e.target.value }
                        })} />
                    </Form.Group>

                    <Form.Group controlId='description' className='mb-3'>
                        <Form.Label>Краткое описание</Form.Label>
                        <Form.Control value={formValues?.description} onChange={e => setFormValue((prev) => {
                            return { ...prev, description: e.target.value }
                        })} />
                    </Form.Group>

                    <Form.Group controlId='address' className='mb-3'>
                        <Form.Label>*Адрес расположения компании (если нет, то адрес регистрации юридического лица)</Form.Label>
                        <Form.Control required value={formValues?.address} onChange={e => setFormValue((prev) => {
                            return { ...prev, address: e.target.value }
                        })} />
                    </Form.Group>

                    <Form.Group controlId='phone' className='mb-3'>
                        <Form.Label>*Телефон (ответственного лица по вопросам партнерства)</Form.Label>
                        <Form.Control required value={formValues?.phone}
                            as={ReactInputMask}
                            mask={"+7 999 999 9999"}
                        onChange={e => setFormValue((prev) => {
                            return { ...prev, phone: e.target.value }
                        })} />
                    </Form.Group>

                    <Form.Group controlId='logo' className='mb-3'>
                        <Form.Label>Логотип компании</Form.Label>
                        <input type='file' className='form-control' onChange={(e) => setFormValue((prev) => ({ ...prev, logo: e.target.files?.[0] }))} />
                    </Form.Group>
                    {error!=='' && <Form.Text className="text-danger">{error}</Form.Text>}
                    <Button variant="primary" type="submit">Завершить регистрацию</Button>
                </Form>
            </Modal.Body>

        </Modal>
    </>
}

export default Create