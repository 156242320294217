import React, { useState } from 'react'
import { Button, Form, Modal } from 'react-bootstrap';
import { IPartnerShort } from '../../types/partners/IPartnerShort';
import partnerService from '../../api/partner.api';
import { IPartner } from '../../types/partners/IPartner';
import { API_URL } from '../../api';
import { PartnerUpdate } from '../../types/partners/PartnerUpdate';
import { convertImagePath } from './Image';

type Props = {
    partner: IPartnerShort
    update: ()=>void
}

const ConfirmRegistration = (props: Props) => {

    const [show, setShow] = useState(false);

    const [partnerInfo, setPartnerInfo] = useState<IPartner>({} as IPartner)

    const [formValues, setFormValue] = useState<PartnerUpdate>(new PartnerUpdate())

    const handleClose = () => setShow(false);
    const handleShow = () =>  {
        setShow(true);
        getPartnerInfo()
    }

    const getPartnerInfo = ()=>{
        partnerService.getPartner(props.partner.id).then((response)=>{
            setPartnerInfo(response.data)
            setFormValue({
                ...response.data,
                logo:null
            })
        })
    }

    const handleForm = (e: React.FormEvent)=>{
        e.preventDefault()
        partnerService.completeRegistration(formValues).then((response)=>{
            console.log(response)
            handleClose()
            props.update()
        }).catch((error)=>{
            console.log(error)
        })
    }

    return <>

        {
            props.partner.confirmed ? <span>Подтвержден</span>
                : <div className='d-flex align-items-center'>
                    <span className='me-3'>В обработке</span>
                    <Button variant="primary" onClick={handleShow} >
                        Подтвердить
                    </Button>
                </div>
        }


        <Modal size='lg' show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Завершение регистрации компании</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {
                    partnerInfo && <Form onSubmit={handleForm}>

                    <Form.Group controlId='bin' className='mb-3'>
                        <Form.Label>*БИН</Form.Label>
                        <Form.Control value={formValues?.bin} onChange={e=>setFormValue((prev)=>{
                            return {...prev, bin: e.target.value}
                        })} required/>
                    </Form.Group> 

                    <Form.Group controlId='lawName' className='mb-3'>
                        <Form.Label>*Юридическое имя</Form.Label>
                        <Form.Control required value={formValues?.lawName} onChange={e=>setFormValue((prev)=>{
                            return {...prev, lawName: e.target.value}
                        })}/>
                    </Form.Group> 

                    <Form.Group controlId='name' className='mb-3'>
                        <Form.Label>*Отображаемое в системе имя</Form.Label>
                        <Form.Control required value={formValues?.name} onChange={e=>setFormValue((prev)=>{
                            return {...prev, name: e.target.value}
                        })}/>
                    </Form.Group> 

                    <Form.Group controlId='description' className='mb-3'>
                        <Form.Label>Краткое описание</Form.Label>
                        <Form.Control value={formValues?.description} onChange={e=>setFormValue((prev)=>{
                            return {...prev, description: e.target.value}
                        })}/>
                    </Form.Group>
                    
                    <Form.Group controlId='address' className='mb-3'>
                        <Form.Label>*Адрес расположения компании (если нет, то адрес регистрации юридического лица)</Form.Label>
                        <Form.Control required value={formValues?.address} onChange={e=>setFormValue((prev)=>{
                            return {...prev, address: e.target.value}
                        })}/>
                    </Form.Group>

                    <Form.Group controlId='phone' className='mb-3'>
                        <Form.Label>*Телефон (ответственного лица по вопросам партнерства)</Form.Label>
                        <Form.Control required value={formValues?.phone} onChange={e=>setFormValue((prev)=>{
                            return {...prev, phone: e.target.value}
                        })}/>
                    </Form.Group>

                    <Form.Group controlId='logo' className='mb-3'>
                        <Form.Label>Логотип компании</Form.Label>
                        <div className='d-flex flex-column my-2'>
                            <small>Текущий логотип</small>
                            <img style={{width: 100, aspectRatio:'1 / 1', objectFit: 'contain',border: '1px solid #ccc', borderRadius: '4px'}} 
                            src={convertImagePath(props.partner.logo)} 

                            alt="" />
                        </div>
                        <input type='file' className='form-control' onChange={(e)=>setFormValue((prev)=>({...prev, logo: e.target.files?.[0]}))}/>
                    </Form.Group>

                    <Button variant="primary" type="submit">Завершить регистрацию</Button>
                </Form>
                }
            </Modal.Body>
        </Modal>
    </>
}

export default ConfirmRegistration