import React, { useState } from 'react'
import { Button, Form, Modal } from 'react-bootstrap';
import { UserCreate } from '../../../types/user/UserCreate';
import ReactInputMask from 'react-input-mask';
import userService from '../../../api/users.api';

type Props = {
    id: number
    update: ()=>void
}

const CreateEmployee = (props: Props) => {

    const [show, setShow] = useState(false);
    const [formValues, setFormValues] = useState<UserCreate>(new UserCreate(props.id));

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormValues(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        
        userService.create(formValues).then(()=>{props.update(); handleClose()}).catch((error)=>{console.log(error)})
    };



    return <>
        <Button variant="primary" onClick={handleShow}>
            Добавить сотрудника в систему
        </Button>

        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Новый сотрудник</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <Form onSubmit={handleSubmit}>
            <Form.Group controlId="firstName" className="mb-3">
                <Form.Label>Имя</Form.Label>
                <Form.Control
                    type="text"
                    name="firstName"
                    value={formValues.firstName}
                    onChange={handleChange}
                />
            </Form.Group>

            <Form.Group controlId="secondName" className="mb-3">
                <Form.Label>Фамилия</Form.Label>
                <Form.Control
                    type="text"
                    name="secondName"
                    value={formValues.secondName}
                    onChange={handleChange}
                />
            </Form.Group>

            <Form.Group controlId="email" className="mb-3">
                <Form.Label>Почта</Form.Label>
                <Form.Control
                    type="email"
                    name="email"
                    value={formValues.email}
                    onChange={handleChange}
                />
            </Form.Group>

            <Form.Group controlId="phone" className="mb-3">
                <Form.Label>Телефон</Form.Label>
                <Form.Control type="text" name="phone"
                 value={formValues.phone}
                 mask={'+7 (999) 999-99-99'}
                 as={ReactInputMask}
                 onChange={handleChange}
                />
            </Form.Group>

            <Button variant="primary" type="submit">
                Добавить
            </Button>
        </Form>
            </Modal.Body>
        </Modal>
    </>
}

export default CreateEmployee