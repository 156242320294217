import axiosInstance from "."
import { UserCreate } from "../types/user/UserCreate"
import DictionaryApi from "./dictionary.api";

class UserService {
    async findAll(query:string) {
        const response = await axiosInstance.get('/api/user/search?query='+query)
        return response
    }

    async findByEmail(email:string) {
        const response = await axiosInstance.get('/api/user/searchByEmail/'+email)
        return response
    }

    async getRoles() {
        const response = await axiosInstance.get('/api/role')
        return response 
    }

    async create(fd:UserCreate) {
        const response = await axiosInstance.post('/api/user/createEmployee', fd)
        return response
    }

    async delete(id: number) {
        const response = await DictionaryApi.delete('/user/delete', id)
        return response
    }
}

const userService = new UserService()
export default userService