export class PartnerUpdate {
    id:number
    lawName:string
    bin:string
    name:string
    description:string
    logo?:File
    address:string
    phone:string

    constructor() {
        this.id = 0
        this.lawName = ''
        this.bin = ''
        this.name = ''
        this.description = ''
        this.address = ''
        this.phone = ''
    }
}