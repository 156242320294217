import React from 'react';
import {Button, Dropdown, Modal} from "react-bootstrap";
import {IPartnerShort} from "../../types/partners/IPartnerShort";
import partnerService from "../../api/partner.api";
import {toast} from "react-toastify";

type IPartnerShortProps = {
    item: IPartnerShort
    update:() => void
}
const DeletePartners = ({item, update}: IPartnerShortProps) => {
    const [showModal, setShowModal] = React.useState<boolean>(false);
    const handleOpen = () => {
        setShowModal(true);
    }

    const handleClose = () => {
        setShowModal(false);
    }

    const handleForm = async () => {
        try {
            const result = await partnerService.delete(item.id)
            update()
            toast.success(`Компания ${item.name} успешно удалена`);
            setShowModal(false)
        } catch (error: any) {
            toast.error('Произошла ошибка при удалении компании:', error?.response?.data);
        }
    }

    return (
        <>
            <Dropdown.Item onClick={handleOpen}>
                Удалить компанию из системы
            </Dropdown.Item>
            <Modal show={showModal} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Вы уверены, что хотите удалить эту компанию? </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        {item.name}
                    </p>

                    <p style={{color: "#dc3545"}}>Это действие нельзя отменить</p>
                    <Button onClick={handleForm}>
                        Удалить компанию из системы
                    </Button>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default DeletePartners;