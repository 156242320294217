import React from 'react';
import {Button, Dropdown, Modal} from "react-bootstrap";
import {IUserList} from "../../../types/user/IUserList";
import userService from "../../../api/users.api";
import {toast} from "react-toastify";

type IDeleteUserProps = {
    item: IUserList
    update:() => void
}

const DeleteUser = ({item, update}: IDeleteUserProps) => {
    const [showModal, setShowModal] = React.useState<boolean>(false);
    const handleOpen = () => {
        setShowModal(true);
    }

    const handleClose = () => {
        setShowModal(false);
    }

    const handleForm = async () => {
        try {
            const result = await userService.delete(item.id)
            update()
            toast.success(`Пользователь ${item.firstName} ${item.secondName} успешно удален`);
            setShowModal(false)
        } catch (error: any) {
            toast.error('Произошла ошибка при удалении пользователя:', error?.response?.data);
        }
    }

    return (
        <div>
            <Dropdown.Item onClick={handleOpen}>Удалить пользователя из системы</Dropdown.Item>

            <Modal show={showModal} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Вы уверены, что хотите удалить этого пользователя? </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        {`${item.firstName} ${item.secondName}`}
                    </p>

                    <p style={{color: "#dc3545"}}>Это действие нельзя отменить</p>
                    <Button onClick={handleForm}>
                        Удалить пользователя из системы
                    </Button>
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default DeleteUser;