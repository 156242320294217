import React, { useEffect, useState } from 'react'
import partnerService from '../../api/partner.api'
import { IPartnerEmployee } from '../../types/partners/IPartnerEmployee'
import Image from '../Partners/Image'
import { ListGroup } from 'react-bootstrap'
import EmployeeOperations from './EmployeeOperations/Index'
import SignEmployee from './SignEmployee'
import CreateEmployee from './EmployeeOperations/CreateEmployee'
import { IPartner } from '../../types/partners/IPartner'

type Props = {
    partner: IPartner
}

const Employees = (props: Props) => {

    const [list, setList] = useState<IPartnerEmployee[]>([])

    const update = () => {
        partnerService.getEmployees(props.partner.id).then((response) => {
            setList(response.data)
        }).catch((error) => {
            console.log(error)
        })
    }

    useEffect(() => {
        update()
    }, [])

    return <>

        <div className="d-flex mb-3" style={{gap:20}}>
        {
            props.partner.limit === list.length ? 
            <>
                <p className="text-danger">Для добавления новых сотрудников, увеличьте лимит сотрудников для компании</p>
            </>:
            <>
            <SignEmployee id={props.partner.id} update={update}/>
            <CreateEmployee id={props.partner.id} update={update}/>
            </>
        }
        </div>

        <table className='table table-bordered'>
            <thead>
                <tr>
                    <th>#</th>
                    <th>Фото</th>
                    <th>Имя</th>
                    <th>Почта</th>
                    <th>Роли</th>
                    <th>Операции</th>
                </tr>
            </thead>
            <tbody>
                {
                    list.map((item) => {
                        return (
                            <tr key={item.id}>
                                <td>{item.id}</td>
                                <td><Image path={item.photo} /></td>
                                <td>{item.fullName}</td>
                                <td>{item.email}</td>
                                <td><ListGroup>
                                    {
                                        item.roles.map((role, index) => {
                                            return <ListGroup.Item key={index}>{role}</ListGroup.Item>
                                        })
                                    }
                                </ListGroup></td>
                                <td>
                                    <EmployeeOperations companyId={props.partner.id} employee={item} update={update}/>
                                </td>
                            </tr>
                        )
                    })
                }
            </tbody>
        </table>
    </>
}

export default Employees