import React, { useState } from 'react'
import { Button, Dropdown, Modal } from 'react-bootstrap'
import { IPartnerEmployee } from '../../../types/partners/IPartnerEmployee'
import partnerService from '../../../api/partner.api'

type Props = {
    companyId: number,
    employee: IPartnerEmployee,
    update: () => void
}

const Unassign = (props: Props) => {

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => {
        setShow(true);
    }

    const handleUnssign = () => {
        partnerService.unassignEmployee(props.companyId, props.employee.id).then((response) => {
            props.update()
            handleClose()
        }).catch((error) => {
            console.log(error)
        })
    }

    return (
        <>
            <Dropdown.Item onClick={handleShow}>Открепить сотрудника от компании</Dropdown.Item>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Открепление сотрудника от компании</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p className='text-danger'>Вы уверены, что хотите открепить сотрудника от компании?</p>
                    <p><small>Сотрудника можно перекрепить заново</small></p>

                    <Button variant={'danger'} onClick={handleUnssign}>Открепить</Button>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default Unassign