import React from 'react'
import { IPartner } from '../../types/partners/IPartner'
import { ListGroup } from 'react-bootstrap'
import Image from '../Partners/Image'

type Props = {
    partner: IPartner
}

const Info = (props: Props) => {
    return (
        <ListGroup>
            <ListGroup.Item>
                <div className="info-item-block">
                    <div className="title">БИН</div>
                    <div className="value">{props.partner.bin}</div>
                </div>
            </ListGroup.Item>

            <ListGroup.Item>
                <div className="info-item-block">
                    <div className="title">Юридическое имя</div>
                    <div className="value">{props.partner.lawName}</div>
                </div>
            </ListGroup.Item>

            <ListGroup.Item>
                <div className="info-item-block">
                    <div className="title">Отображаемое имя</div>
                    <div className="value">{props.partner.name}</div>
                </div>
            </ListGroup.Item>

            <ListGroup.Item>
                <div className="info-item-block">
                    <div className="title">Описание</div>
                    <div className="value">{props.partner.description}</div>
                </div>
            </ListGroup.Item>

            <ListGroup.Item>
                <div className="info-item-block">
                    <div className="title">Контакты ответственного лица</div>
                    <div className="value"><a href={'tel:'+props.partner.phone}>{props.partner.phone}</a></div>
                </div>
            </ListGroup.Item>

            <ListGroup.Item>
                <div className="info-item-block">
                    <div className="title">Логотип</div>
                    <div className="value"><Image path={props.partner.logo}/></div>
                </div>
            </ListGroup.Item>

        </ListGroup>
    )
}

export default Info