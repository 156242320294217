import axiosInstance, { fileUploadInstance } from ".";
import { PartnerUpdate } from "../types/partners/PartnerUpdate";
import { ToggleRole } from "../types/user/ToggleRole";
import DictionaryApi from "./dictionary.api";

class PartnerService {
    baseURL:string
    constructor() {
        this.baseURL = `/api/partner`
    }
    async getPartners() {
        const response = await axiosInstance.get(`${this.baseURL}/company`)
        return response
    }

    async getPartner(id:number) {
        const response = await axiosInstance.get(`${this.baseURL}/company/${id}`)
        return response
    }

    async completeRegistration(data:PartnerUpdate) {
        const fd = new FormData()
        fd.append('id', String(data.id))
        fd.append('lawName', data.lawName)
        fd.append('bin', data.bin)
        fd.append('name', data.name)
        fd.append('description', data.description)
        fd.append('address', data.address)
        fd.append('phone', data.phone.replaceAll(' ',''))
        if(data.logo) {
            fd.append('logo', data.logo)
        }
        const response = await fileUploadInstance.put(`${this.baseURL}/company/completeRegistration`, fd)
        return response
    }

    async update(data:PartnerUpdate) {
        const fd = new FormData()
        fd.append('id', String(data.id))
        fd.append('lawName', data.lawName)
        fd.append('bin', data.bin)
        fd.append('name', data.name)
        fd.append('description', data.description)
        fd.append('address', data.address)
        fd.append('phone', data.phone.replaceAll(' ',''))
        if(data.logo) {
            fd.append('logo', data.logo)
        }
        const response = await fileUploadInstance.put(`${this.baseURL}/company/update`, fd)
        return response
    }

    async create(data:PartnerUpdate) {
        const fd = new FormData()
        fd.append('lawName', data.lawName)
        fd.append('bin', data.bin)
        fd.append('name', data.name)
        fd.append('description', data.description)
        fd.append('address', data.address)
        fd.append('phone', data.phone.replaceAll(' ',''))
        if(data.logo) {
            fd.append('logo', data.logo)
        }
        const response = await fileUploadInstance.post(`${this.baseURL}/company/create`, fd)
        return response
    }

    async delete(id: number) {
        const response = await DictionaryApi.delete('company', id)
        return response
    }

    async getEmployees(id:number) {
        const response = await axiosInstance.get(`${this.baseURL}/company/${id}/employees`)
        return response
    }

    async getRoles() {
        const response = await axiosInstance.get(`${this.baseURL}/roles`)
        return response
    }

    async toggleRole(fd:ToggleRole) {
        const response = await axiosInstance.put(`${this.baseURL}/roles/toggleRole`, fd)
        return response
    }

    async unassignEmployee(id:number, userId:number) {
        const response = await axiosInstance.post(`${this.baseURL}/company/${id}/unassign/${userId}`)
        return response
    }

    async assignEmployee(id:number, userId:number) {
        const response = await axiosInstance.post(`${this.baseURL}/company/${id}/assign/${userId}`)
        return response
    }

    async findUser(query:string) {
        const response = await axiosInstance.get(`${this.baseURL}/company/findUser/${query}`)
        return response
    }

    async setLimitOfWorkers(id:number, limit:number) {
        const response = await axiosInstance.put(`${this.baseURL}/company/${id}/limit/${limit}`)
        return response
    }

    async setActivationMonth(id:number, months:number, date:string) {
        const response = await axiosInstance.put(`${this.baseURL}/company/${id}/setActivation`, {
            months,
            date
        })
        return response
    }
}

const partnerService = new PartnerService();
export default partnerService