import React, { useEffect, useState } from 'react'
import { GetDictionaryTitle } from '../../helpers/GetDictionaryTitle'
import { Link } from 'react-router-dom'
import { ListGroup } from 'react-bootstrap'
import { IDictionaryItem } from '../../types/dictionary/IDictionaryItem'
import dictionaryService from '../../api/dictionary.api'
import { observer } from 'mobx-react'
import { useStores } from '../../stores'
import DictionaryCreate from './DictionaryCreate'
import DictionaryEdit from './DictionaryEdit'
import DictionaryDelete from './DictionaryDelete'

type Props = {
    entity: string
}

function DictionaryIndex({ entity }: Props) {

    const [list, setList] = useState<IDictionaryItem[]>([])

    const { routerStore } = useStores()

    const update = ()=>{
        dictionaryService.findAll(entity).then((response) => {
            setList(response.data)
        }).catch((error) => {
            console.log(error)
        })
    }

    useEffect(() => {
        console.log(entity)
        routerStore.setCurrentPath(`/${entity}`)
        update()
    }, [entity])

    return <>
        <h3>Список "{GetDictionaryTitle(entity)}"</h3>

        <DictionaryCreate entity={entity} update={update}/>

        <ListGroup style={{ maxWidth: 700 }}>
            {
                list.length > 0 && list.map((item) => {
                    return <ListGroup.Item key={item.id}>
                        <div className="d-flex justify-content-between">
                            <p>
                                {item.name} <br />
                                {item.nameKZ} <br />
                                {item.nameEN}  
                            </p>
                            <ul style={{listStyle: 'none', padding:0,margin:0, display:'flex', gap: 10}}>
                                <li><DictionaryEdit item={item} entity={entity} update={update}/></li>
                                <li><DictionaryDelete item={item} entity={entity} update={update}/></li>
                            </ul>
                        </div>
                    </ListGroup.Item>
                })
            }
        </ListGroup>
    </>
}

export default observer(DictionaryIndex)