import React, { useState } from 'react'
import { Dropdown, ListGroup, Modal } from 'react-bootstrap'
import { IPartnerEmployee } from '../../../types/partners/IPartnerEmployee'
import { IRole } from '../../../types/user/IRole'
import partnerService from '../../../api/partner.api'

type Props = {
    employee: IPartnerEmployee,
    update: () => void
}

const ManageRoles = (props: Props) => {

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => {
        update()
        setShow(true);
        console.log(props.employee)
    }

    const [list, setList] = useState<IRole[]>([])

    const update = () => {
        partnerService.getRoles().then((response) => {
            setList(response.data)
        }).catch((error) => {
            console.log(error)
        })
    }

    const setRole = (roleId: number, checked: boolean) => {
        partnerService.toggleRole({
            userId: props.employee.id,
            roleId,
            checked
        }).then((response) => {
            props.update()
        }).catch((error) => {
            console.log(error)
        })
    }

    return (
        <>
            <Dropdown.Item onClick={handleShow}>Настройка ролей</Dropdown.Item>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Настройка ролей</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ListGroup>
                        {
                            list.map((item) => {
                                return <ListGroup.Item key={item.id}>
                                    <input type="checkbox"
                                        defaultChecked={props.employee.roles.some(role => role === item.description)}
                                        onChange={(e) => setRole(item.id, e.target.checked)}
                                    /> {item.description}
                                </ListGroup.Item>
                            })
                        }
                    </ListGroup>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default ManageRoles