import React, { useState } from 'react'
import { Button, Modal } from 'react-bootstrap';
import { GetDictionaryTitle } from '../../helpers/GetDictionaryTitle';
import { IDictionaryItem } from '../../types/dictionary/IDictionaryItem';
import dictionaryService from '../../api/dictionary.api';

type Props = {
    entity: string,
    item: IDictionaryItem,
    update:()=>void
}

const DictionaryDelete = (props: Props) => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleForm = ()=>{
        dictionaryService.delete(props.entity, props.item.id).then((response)=>{
            props.update()
            handleClose()
        }).catch((error)=>{
            console.log(error)
        })
    }
  
    return (
      <>
        <Button variant="danger" onClick={handleShow}>
          Удалить
        </Button>
  
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Удаление {GetDictionaryTitle(props.entity)} - {props.item.name}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Вы уверены, что хотите удалить данный элемент?</p>
            <Button className='mt-3' onClick={handleForm}>Да, удалить!</Button>
          </Modal.Body>
        </Modal>
      </>
    );
}

export default DictionaryDelete