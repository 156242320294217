
export const GetDictionaryTitle = (route:string) => {
    switch (route) {
        case 'reasonForHiring': return "Причина найма";
        case 'workContractType': return "Тип договора";
        case 'englishLevel': return "Уровень английского";
        case 'educationDegree': return "Степень образования";
        case 'workScheduleType': return "График работы";
        case 'workExperienceType': return "Опыт работы";
        case 'bonusType': return 'Тип бонуса';
        case 'language': return 'Языки';
        case 'languageDegree': return 'Уровень языка'
        case 'commentType' : return 'Тип комментария';
        case 'meetingType' : return 'Формат собеседования';
        default: return route
    }
}