import React, { useState } from 'react'
import { Dropdown, ListGroup, Modal } from 'react-bootstrap'
import { IPartnerEmployee } from '../../../types/partners/IPartnerEmployee'
import { IRole } from '../../../types/user/IRole'
import partnerService from '../../../api/partner.api'
import { IUserList } from '../../../types/user/IUserList'
import userService from '../../../api/users.api'
import { IUser } from '../../../types/user/IUser'

type Props = {
    user:IUserList,
    update: () => void
}

const ManageRoles = (props: Props) => {

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => {
        update()
        setShow(true);
    }
    const [user, setUser] = useState<IUser | null>(null)
    const [list, setList] = useState<IRole[]>([])

    const update = () => {
        userService.findByEmail(props.user.email).then((response) => {
            setUser(response.data)
        }).catch((error) => {
            console.log(error)
        })

        userService.getRoles().then((response)=>{
            setList(response.data)
        }).catch((error)=>{
            console.log(error)
        })
    }

    const setRole = (roleId: number, checked: boolean) => {
        partnerService.toggleRole({
            userId: props.user.id,
            roleId,
            checked
        }).then((response) => {
            props.update()
        }).catch((error) => {
            console.log(error)
        })
    }

    return (
        <>
            <Dropdown.Item onClick={handleShow}>Настройка ролей</Dropdown.Item>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Настройка ролей</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        user && <ListGroup>
                        {
                            list.map((item) => {
                                return <ListGroup.Item key={item.id}>
                                    <input type="checkbox"
                                        defaultChecked={user.roles.some(role => role === item.name)}
                                        onChange={(e) => setRole(item.id, e.target.checked)}
                                    /> {item.description}
                                </ListGroup.Item>
                            })
                        }
                    </ListGroup>
                    }
                </Modal.Body>
            </Modal>
        </>
    )
}

export default ManageRoles