import React, { useState } from 'react';
import { Dropdown, Form } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import partnerService from '../../api/partner.api';
type Props = {
    id: number
    currentLimit: number
    update:()=>void
}

const SetLimitOfWorkers = (props: Props) => {

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [limit, setLimit] = useState<number>(props.currentLimit)

    const handleForm = (e: React.FormEvent) => {
        e.preventDefault()
        partnerService.setLimitOfWorkers(props.id, limit).then((response) => {
            props.update()
            handleClose()
        }).catch((error) => {
            console.log(error)
        })
    }

    return (
        <>
            <Dropdown.Item  onClick={handleShow}>
                Изменить лимит сотрудников
            </Dropdown.Item>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Изменение лимита  возможного количества сотрудников</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleForm}>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Лимит</Form.Label>
                            <Form.Control
                                type="number"
                                placeholder="Лимит"
                                autoFocus
                                value={limit}
                                onChange={(e) => { setLimit(Number(e.target.value)) }}
                            />
                        </Form.Group>

                        <Button variant="primary" type="submit">
                            Сохранить изменения
                        </Button>
                    </Form>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default SetLimitOfWorkers