import React, {useEffect, useState} from 'react'
import {Container, Dropdown} from 'react-bootstrap'
import ManualRegistrationModal from './Create'
import partnerService from '../../api/partner.api'
import {IPartnerShort} from '../../types/partners/IPartnerShort'
import ConfirmRegistration from './ConfirmRegistration'
import Image from './Image'
import {Link} from 'react-router-dom'
import {useStores} from '../../stores'
import SetLimitOfWorkers from './SetLimitOfWorkers'
import SetActivationMonth from './SetActivationMonth'
import {formatDateString} from '../../helpers/formatDateString'
import {getActivationDateStatus} from '../../helpers/getActivationDateStatus'
import EditInfo from './EditInfo'
import DeletePartners from "./DeletePartners";

type Props = {}

const Index = (props: Props) => {

    const {routerStore} = useStores()

    const [list, setList] = useState<IPartnerShort[]>([])

    const update = () => {
        partnerService.getPartners().then((response) => {
            setList(response.data)
        }).catch((error) => {
            console.log(error)
        })
    }

    useEffect(() => {
        update();
        routerStore.setCurrentPath(`/partners`)
    }, [])

    return <Container className='ms-0'>
        <h3>Партнеры</h3>
        <ManualRegistrationModal className={'my-3'} update={update}/>
        <table className='table table-bordered'>
            <thead>
            <tr>
                <th style={{width: 50}}>#</th>
                <th style={{width: 70}}>Лого</th>
                <th>Название</th>
                <th>БИН</th>
                <th>Статус</th>
                <th>Лимит сотрудников</th>
                <th>Активен до</th>
                <th>Операции</th>
            </tr>
            </thead>
            <tbody>
            {
                list.map((item, index) => {
                    return <tr key={item.id}>

                        <td>{item.id}</td>
                        <td>
                            <Image path={item.logo}/>
                        </td>
                        <td><Link to={"/partner/" + item.id}>{item.name}</Link></td>
                        <td>{item.bin}</td>
                        <td><ConfirmRegistration partner={item} update={update}/></td>
                        <td>
                            <span className='me-3'>{item.limit}</span>

                        </td>
                        <td>

                            <span className="me-3">{formatDateString(item.completeActivation)}</span>
                            {
                                getActivationDateStatus(item.completeActivation) === 'stop' ?
                                    <p className="text-danger">Истек срок активации</p>
                                    : getActivationDateStatus(item.completeActivation) === 'warning' ?
                                        <p className="text-warning">Срок активации заканчивается</p>
                                        :
                                        <></>
                            }

                        </td>
                        <td>
                            <Dropdown align={"end"}>
                                <Dropdown.Toggle variant="light" id="dropdown-basic">
                                    <i className="fa-solid fa-ellipsis-vertical"></i>
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <SetLimitOfWorkers
                                        id={item.id}
                                        currentLimit={item.limit}
                                        update={update}/>
                                    <SetActivationMonth id={item.id} update={update}/>
                                    <EditInfo partner={item} update={update}/>
                                    <DeletePartners item={item} update={update}/>
                                </Dropdown.Menu>
                            </Dropdown>
                        </td>
                    </tr>
                })
            }
            </tbody>
        </table>
    </Container>
}

export default Index