import axiosInstance from ".";
import { IDictionaryCreate } from "../types/dictionary/IDictionaryCreate";
import { IDictionaryItem } from "../types/dictionary/IDictionaryItem";

class DictionaryService {
    async findAll(entity: string) {
        const response = await axiosInstance.get(`/api/${entity}/`)
        return response
    }

    async findOne(entity: string, id: number) {
        const response = await axiosInstance.get('/api/' + entity + '/' + id)
        return response
    }

    async create(entity: string, fd: IDictionaryCreate) {
        const response = await axiosInstance.post(`/api/${entity}/`, fd)
        return response
    }

    async update(entity: string, fd: IDictionaryItem) {
        const response = await axiosInstance.put(`/api/${entity}/` + fd.id, fd)
        return response
    }

    async delete(entity: string, id: number) {
        const response = await axiosInstance.delete(`/api/${entity}/${id}`)
        return response
    }
}

const dictionaryService = new DictionaryService()
export default dictionaryService