export class UserCreate {
    firstName:string
    secondName:string
    email:string
    phone:string
    companyId:number
    constructor(companyId:number) {
        this.firstName = ''
        this.secondName = ''
        this.email = ''
        this.phone = ''
        this.companyId = companyId
    }
}