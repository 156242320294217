import React, { useState } from 'react'
import { Alert, Button, Container, Form } from 'react-bootstrap'
import authService from '../../api/auth.api'
import { useStores } from '../../stores'
import { useNavigate } from 'react-router-dom'

type Props = {}

const Page = (props: Props) => {

    const { authStore } = useStores();
    const navigator = useNavigate();
    const [email, setEmail] = useState<string>('')
    const [password, setPassword] = useState<string>('')
    const [error, setError] = useState<string | null>(null)
    const [loading, setLoading] = useState<boolean>(false)

    const handleForm = (e: React.FormEvent) => {
        e.preventDefault()

        setLoading(true)
        setError(null)
        authService.login({ email, password }).then((response) => {
            if (response.data.roles.includes('admin')) {
                authStore.setAuth(true)
                navigator('/')
            } else {
                setError("У вас нет доступа к админ панели. Обратитесь к администратору")
            }
            setLoading(false)
        }).catch((e) => {
            authStore.setAuth(false)
            setLoading(false)
            setError('Неверная почта или пароль')
        })
    }
    return (
        <div id="authLayout">
            <Container>
                <div className='sign-in-container'>
                    <div className="white-block">
                        <h1>Вход</h1>
                        <Form onSubmit={handleForm}>
                            <Form.Group className='mb-3'>
                                <Form.Label>Эл. почта</Form.Label>
                                <Form.Control placeholder='mymail@example.com' type="email" onChange={(e) => { setEmail(e.target.value) }} />
                            </Form.Group>
                            <Form.Group className='mb-3'>
                                <Form.Label>Пароль</Form.Label>
                                <Form.Control placeholder='*****' type="password" onChange={(e) => { setPassword(e.target.value) }} />
                            </Form.Group>
                            {
                                error && <Alert variant='danger'>{error}</Alert>
                            }
                            <Button type="submit" disabled={loading}>{loading ? 'Вход...' : 'Вход'}</Button>
                        </Form>
                    </div>
                </div>
            </Container>
        </div>
    )
}

export default Page