import React, { useState } from 'react'
import { API_URL } from '../../api';
import { Modal } from 'react-bootstrap';

type Props = {
    path: string
}

export const convertImagePath = (path: string) => {

    if (path.includes('http') || !path.includes('uploads')) {
        return API_URL + path
    } else {
        return API_URL + path
    }
}

const Image = (props: Props) => {

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    

    return (
        <>
            <img src={convertImagePath(props.path)} style={{
                width: 50,
                aspectRatio: '1 / 1',
                objectFit: 'contain',
                border: '1px solid lightgrey',
                borderRadius: 5,
                cursor: 'pointer',
            }} onClick={handleShow} />

            <Modal show={show} onHide={handleClose}>
                <Modal.Body>
                    <img src={convertImagePath(props.path)} style={{
                        width: '100%',
                        aspectRatio: '1 / 1',
                        objectFit: 'contain',
                        border: '1px solid lightgrey',
                        borderRadius: 10
                    }} />
                </Modal.Body>
            </Modal>
        </>
    )
}

export default Image