import React, { useState } from 'react'
import { Button, Dropdown, Form, Modal } from 'react-bootstrap';
import partnerService from '../../api/partner.api';

type Props = {
    id: number
    update: () => void
}

const SetActivationMonth = (props: Props) => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => {
        setShow(true);
        setMonths(1)
    }

    const [months, setMonths] = useState<number>(1)
    const [date, setDate] = useState<string>('');

    const handleForm = (e: React.FormEvent) => {
        e.preventDefault()
        partnerService.setActivationMonth(props.id, months, date).then((response) => {
            props.update()
            handleClose()
        }).catch((error) => {
            console.log(error)
        })
    }

    return (
        <>
            <Dropdown.Item onClick={handleShow}>
                Продлить активацию
            </Dropdown.Item>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Продление активации</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleForm}>
                        <Form.Group className='mb-3'>
                            <Form.Label>Выберите количество месяцев (отсчет идет с даты завершения активации)</Form.Label>
                            <Form.Control type="number" min={1} value={months} onChange={(e) => setMonths(parseInt(e.target.value))} />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>либо выберите дату завершения активации</Form.Label>
                            <Form.Control type="date" value={date} onChange={(e) => setDate(e.target.value)}/>
                        </Form.Group>
                        <Button type={"submit"}>Продлить</Button>
                    </Form>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default SetActivationMonth