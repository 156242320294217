import axios, { AxiosInstance } from 'axios';

export const API_URL = process.env.REACT_APP_API_URL;

export const CRM_URL = process.env.REACT_APP_CLIENT_URL;

const axiosInstance: AxiosInstance = axios.create({
  baseURL: API_URL,
  timeout: 1000 * 30,
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true
});

export const fileUploadInstance: AxiosInstance = axios.create({
  baseURL: API_URL,
  timeout: 1000 * 30,
  headers: {
    'Content-Type': 'multipart/form-data',
  },
  withCredentials: true
});

export default axiosInstance;

