import React from 'react'
import { Dropdown } from 'react-bootstrap'
import ManageRoles from './ManageRoles'
import { IPartnerEmployee } from '../../../types/partners/IPartnerEmployee'
import Unassign from './Unassign'

type Props = {
    companyId:number
    employee: IPartnerEmployee,
    update: () => void
}

const Index = (props: Props) => {
    return (
        <Dropdown>
            <Dropdown.Toggle variant="primary" id="dropdown-basic">
                <i className="fa-solid fa-ellipsis-vertical"></i>
            </Dropdown.Toggle>

            <Dropdown.Menu>
                <ManageRoles employee={props.employee} update={props.update} />
                <Unassign companyId={props.companyId} employee={props.employee} update={props.update} />
            </Dropdown.Menu>
        </Dropdown>
    )
}

export default Index