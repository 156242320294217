export function getActivationDateStatus(dateString: string): string {
    const currentDate = new Date();
    const targetDate = new Date(dateString);

    // Проверка, если дата сегодняшняя или раньше
    if (targetDate <= currentDate) {
        return "stop";
    }

    // Вычисление даты, спустя 7 дней
    const oneWeekLater = new Date(currentDate);
    oneWeekLater.setDate(oneWeekLater.getDate() + 7);

    // Проверка, если дата в течение следующих 7 дней
    if (targetDate <= oneWeekLater) {
        return "warning";
    }

    // Если ни одно из условий не выполнилось, возвращаем "good"
    return "good";
}