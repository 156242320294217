import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { AxiosError } from 'axios';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import 'react-toastify/dist/ReactToastify.css';
import './static/style.min.css';
import './static/auth.min.css';

import { useStores } from './stores';
import Preloader from './components/Preloader';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Layout from './components/layouts/main/Layout';
import DictionaryIndex from './pages/Dictionary/DictionaryIndex';
import Index from './pages/Index';
import PartnersIndex from './pages/Partners/Index'
import PartnerDetails from './pages/Partner/Index'
import UsersIndex from './pages/Users/Index'
import authService from './api/auth.api';
import LoginPage from './pages/Login/Page';
import {ToastContainer} from "react-toastify";

export const ROUTES = [
  'educationDegree',
  'englishLevel',
  'workContractType',
  'reasonForHiring',
  'workScheduleType',
  'workExperienceType',
  'bonusType',
  'language',
  'languageDegree',
  'commentType',
  'meetingType'
]

function App() {
  const { authStore } = useStores();
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    authService.refresh().then((response) => {
      if (response.status === 200) {
        authStore.setAuth(true)
        setLoading(false)
      }
    }).catch((e: AxiosError) => {
      setLoading(false)
    })
  }, [authStore])


  return loading ? <Preloader /> : <BrowserRouter>
  <Routes>
    <Route path="/" element={<Layout />}>
      <Route index element={<Index />} />
      <Route path="/partners" element={<PartnersIndex />} />
      <Route path="/partner/:id" element={<PartnerDetails />} />
      <Route path="/users" element={<UsersIndex />} />
      {
        ROUTES.map((r) => {
          return <Route key={r} path={r} element={<DictionaryIndex entity={r} />} />
        })
      }
    </Route>
    <Route path="/login" element={<LoginPage />} />
  </Routes>
    <ToastContainer />
</BrowserRouter>
}

export default observer(App);
