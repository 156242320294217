import React, { useState } from 'react'
import { IPartner } from '../../types/partners/IPartner'
import { IPartnerShort } from '../../types/partners/IPartnerShort'
import { PartnerUpdate } from '../../types/partners/PartnerUpdate'
import partnerService from '../../api/partner.api'
import { AxiosError } from 'axios'
import { Button, Dropdown, Form, Modal } from 'react-bootstrap'
import ReactInputMask from 'react-input-mask'

type Props = {
    partner:IPartnerShort
    update:() => void
}

const EditInfo = (props: Props) => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => {
        setShow(true);
        getPartnerInfo()
    }
    const [error, setError]  = useState<string>('')

    const [partnerInfo, setPartnerInfo] = useState<IPartner>({} as IPartner)

    const [formValues, setFormValue] = useState<PartnerUpdate>(new PartnerUpdate())

    function handleForm(e: React.FormEvent): void {
        e.preventDefault();
        setError('')
        partnerService.update(formValues).then((response) => {
            handleClose();
            props.update();
        }).catch((error:AxiosError) => {
            if(error.response?.status === 409) {
                setError("Компания с таким БИН уже существует")
            }
        })
    }

    const getPartnerInfo = ()=>{
        partnerService.getPartner(props.partner.id).then((response)=>{
            setPartnerInfo(response.data)
            setFormValue({
                ...response.data,
                logo:null
            })
        })
    }

    return <>
        <Dropdown.Item onClick={handleShow} >
            Редактировать
        </Dropdown.Item>

        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Редактирование компании</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleForm}>

                    <Form.Group controlId='bin' className='mb-3'>
                        <Form.Label>*БИН</Form.Label>
                        <Form.Control value={formValues.bin}
                        as={ReactInputMask}
                        mask="999999999999"
                        required onChange={(e)=>setFormValue((prev)=>({...prev,bin:e.target.value}))} />
                    </Form.Group>

                    <Form.Group controlId='lawName' className='mb-3'>
                        <Form.Label>*Юридическое имя</Form.Label>
                        <Form.Control required value={formValues?.lawName}
                        onChange={e => setFormValue((prev) => {
                            return { ...prev, lawName: e.target.value }
                        })} />
                    </Form.Group>

                    <Form.Group controlId='name' className='mb-3'>
                        <Form.Label>*Отображаемое в системе имя</Form.Label>
                        <Form.Control required value={formValues?.name} onChange={e => setFormValue((prev) => {
                            return { ...prev, name: e.target.value }
                        })} />
                    </Form.Group>

                    <Form.Group controlId='description' className='mb-3'>
                        <Form.Label>Краткое описание</Form.Label>
                        <Form.Control value={formValues?.description} onChange={e => setFormValue((prev) => {
                            return { ...prev, description: e.target.value }
                        })} />
                    </Form.Group>

                    <Form.Group controlId='address' className='mb-3'>
                        <Form.Label>*Адрес расположения компании (если нет, то адрес регистрации юридического лица)</Form.Label>
                        <Form.Control required value={formValues?.address} onChange={e => setFormValue((prev) => {
                            return { ...prev, address: e.target.value }
                        })} />
                    </Form.Group>

                    <Form.Group controlId='phone' className='mb-3'>
                        <Form.Label>*Телефон (ответственного лица по вопросам партнерства)</Form.Label>
                        <Form.Control required value={formValues?.phone}
                            as={ReactInputMask}
                            mask={"+7 999 999 9999"}
                        onChange={e => setFormValue((prev) => {
                            return { ...prev, phone: e.target.value }
                        })} />
                    </Form.Group>

                    <Form.Group controlId='logo' className='mb-3'>
                        <Form.Label>Логотип компании</Form.Label>
                        <input type='file' className='form-control' onChange={(e) => setFormValue((prev) => ({ ...prev, logo: e.target.files?.[0] }))} />
                    </Form.Group>
                    {error!=='' && <Form.Text className="text-danger">{error}</Form.Text>}
                    <Button variant="primary" type="submit">Сохранить изменения</Button>
                </Form>
            </Modal.Body>

        </Modal>
    </>
}

export default EditInfo