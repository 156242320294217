import React from 'react'

type Props = {}

const Index = (props: Props) => {
  return (
    <>
        <h3>Админ панель</h3>
        <p>Для управления сущностями системы воспользуйтесь меню слева</p>
    </>
  )
}

export default Index