import React, { useEffect, useState } from 'react'
import { Container } from 'react-bootstrap'
import { IUserList } from '../../types/user/IUserList'
import Image from '../Partners/Image'
import userService from '../../api/users.api'
import Operations from './operations/Index'
import { useStores } from '../../stores'

type Props = {}

const Index = (props: Props) => {
    const { routerStore } = useStores()

    const [list, setList] = useState<IUserList[]>([])
    const [query, setQuery] = useState<string>('')

    const update = () => {
        userService.findAll(query).then((response) => {
            setList(response.data[0])
        }).catch((error) => {
            console.log(error)
        })
    }

    useEffect(() => {
        update()
        routerStore.setCurrentPath(`/users`)
    }, [])

    return <Container className='ms-0'>
        <h3>Пользователи</h3>
        <table className="table table-bordered">
            <thead>
                <tr>
                    <th>ID</th>
                    <th>Фото</th>
                    <th>Имя</th>
                    <th>Почта</th>
                    <th>Операции</th>
                </tr>
            </thead>
            <tbody>
                {
                    list.map((item) => {
                        return <tr key={item.id}>
                            <td>{item.id}</td>
                            <td><Image path={item.photo} /></td>
                            <td>{item.firstName} {item.secondName}</td>
                            <td>{item.email}</td>
                            <td>
                                <Operations user={item} update={update} />
                            </td>
                        </tr>
                    })
                }
            </tbody>
        </table>
    </Container>
}

export default Index