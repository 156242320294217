import React, { useEffect, useState } from 'react'
import partnerService from '../../api/partner.api';
import { IPartner } from '../../types/partners/IPartner';
import { useParams } from 'react-router-dom';
import { Container, ListGroup, ListGroupItem, Tab, Tabs } from 'react-bootstrap';
import { useStores } from '../../stores';
import Info from './Info';
import Employees from './Employees';

type Props = {}

const Index = (props: Props) => {
    const { id } = useParams()

    const { routerStore } = useStores()

    const [partnerInfo, setPartnerInfo] = useState<IPartner | null>(null)

    const getPartnerInfo = () => {
        partnerService.getPartner(Number(id)).then((response) => {
            setPartnerInfo(response.data)
        })
    }

    useEffect(() => {
        getPartnerInfo()
        routerStore.setCurrentPath(`/partners`)
    }, [id])

    return (
        <Container className='ms-0'>
            {
                partnerInfo && <>
                    <h3>{partnerInfo.name}</h3>

                    <Tabs
                        defaultActiveKey="info"
                        id="partner-tabs"
                        className="mb-3"
                    >
                        <Tab eventKey="info" title="Основная информация" style={{backgroundColor:'#fff',padding:20, borderRadius:10}}>
                            <Info partner={partnerInfo}/>
                        </Tab>
                        <Tab eventKey="employees" title="Сотрудники" style={{backgroundColor:'#fff',padding:20, borderRadius:10}}>
                            <Employees partner={partnerInfo}/>
                        </Tab>
                    </Tabs>
                </>
            }
        </Container>
    )
}

export default Index