import React, { useState } from 'react'
import { GetDictionaryTitle } from '../../helpers/GetDictionaryTitle'
import { Link } from 'react-router-dom'
import dictionaryService from '../../api/dictionary.api'
import { IDictionaryCreate } from '../../types/dictionary/IDictionaryCreate'
import { Button, Form, Modal } from 'react-bootstrap'

type Props = {
    entity: string
    update: ()=>void
}

const DictionaryCreate = ({ entity, update }: Props) => {

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [formValues, setFormValue] = useState<IDictionaryCreate>({
        name: '',
        nameKZ:'',
        nameEN:'',
        description: '',
        descriptionKZ:'',
        descriptionEN:''
    })

    const handleForm = (e: React.FormEvent) => {
        e.preventDefault()

        dictionaryService.create(entity, formValues).then((response) => {
            setFormValue({
                name: '',
                nameKZ:'',
                nameEN:'',
                description: '',
                descriptionKZ:'',
                descriptionEN:''
            })
            update()
            handleClose()
        })
    }

    return <>
        <Button variant="primary" className='mb-3' onClick={handleShow}>
            Создать
        </Button>

        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Создание "{GetDictionaryTitle(entity)}"</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleForm} style={{ maxWidth: 500 }}>
                    <Form.Group>
                        <Form.Label>*Название</Form.Label>
                        <Form.Control
                            required={true}
                            value={formValues.name}
                            onChange={(e) => setFormValue((prev) => ({ ...prev, name: e.target.value }))}
                        />
                    </Form.Group>

                    <Form.Group>
                        <Form.Label>Название на казахском</Form.Label>
                        <Form.Control
                            value={formValues.nameKZ}
                            onChange={(e) => setFormValue((prev) => ({ ...prev, nameKZ: e.target.value }))}
                        />
                    </Form.Group>

                    <Form.Group>
                        <Form.Label>Название на английском</Form.Label>
                        <Form.Control
                            value={formValues.nameEN}
                            onChange={(e) => setFormValue((prev) => ({ ...prev, nameEN: e.target.value }))}
                        />
                    </Form.Group>

                    <Form.Group>
                        <Form.Label>Описание</Form.Label>
                        <Form.Control
                            value={formValues.description}
                            as={'textarea'}
                            onChange={(e) => setFormValue((prev) => ({ ...prev, description: e.target.value }))}
                        />
                    </Form.Group>

                    <Form.Group>
                        <Form.Label>Описание на казахском</Form.Label>
                        <Form.Control
                            value={formValues.descriptionKZ}
                            as={'textarea'}
                            onChange={(e) => setFormValue((prev) => ({ ...prev, descriptionKZ: e.target.value }))}
                        />
                    </Form.Group>

                    <Form.Group>
                        <Form.Label>Описание на английском</Form.Label>
                        <Form.Control
                            value={formValues.descriptionEN}
                            as={'textarea'}
                            onChange={(e) => setFormValue((prev) => ({ ...prev, descriptionEN: e.target.value }))}
                        />
                    </Form.Group>

                    <Button type="submit" className='mt-3'>Отправить</Button>
                </Form>
            </Modal.Body>
        </Modal>


    </>
}

export default DictionaryCreate