import React, { useState } from 'react'
import { Button, Form, ListGroup, Modal } from 'react-bootstrap';
import partnerService from '../../api/partner.api';
import { IUserList } from '../../types/user/IUserList';

type Props = {
    id: number
    update: () => void
}

const SignEmployee = (props: Props) => {

    const [show, setShow] = useState(false);

    const [result, setResult] = useState<IUserList[]>([])

    const [userSelected, setUserSelected] = useState<boolean>(false)
    const [userId, setUserId] = useState<number>(0)

    const handleClose = () => setShow(false);
    const handleShow = () => {
        setShow(true);
    }

    const [error, setError] = useState<string>('')

    const [searchQuery, setSearchQuery] = useState('')

    const handleSearch = (value: string) => {
        setSearchQuery(value)
        if (value.length > 2) {
            partnerService.findUser(value).then((response) => {
                setResult(response.data)
            }).catch((error) => {
                console.log(error)
            })
        } else {
            setResult([])
        }

    }

    return <>
        <Button variant="primary" onClick={handleShow}>Добавить сотрудника</Button>

        <Modal show={show} onHide={handleClose}>
            <Modal.Header>
                <Modal.Title>Добавление сотрудника</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group>
                        <Form.Label>Поиск по почте, имени или фамилии</Form.Label>
                        <Form.Control type="text" value={searchQuery} onChange={(e) => handleSearch(e.target.value)} />
                    </Form.Group>
                    <ListGroup className="my-3">
                        {
                            result.length > 0 && result.map((item) => {
                                return <ListGroup.Item>
                                    <Form.Check
                                        type={'radio'}
                                        label={`${item.firstName} ${item.secondName} (${item.email})`}
                                        onChange={(e) => {
                                            setUserSelected(e.target.checked)
                                            setUserId(item.id)
                                        }}
                                        id={`disabled-default-${item.id}`}
                                    />
                                </ListGroup.Item>
                            })
                        }
                    </ListGroup>

                    {
                        error !== '' && <Form.Text className="text-danger">{error}</Form.Text>
                    }

                    {
                        userSelected && <Button onClick={() => {
                            setError('')
                            partnerService.assignEmployee(props.id, userId).then((response) => {
                                setSearchQuery('')
                                setResult([])
                                setUserSelected(false)
                                props.update()
                                handleClose()
                            }).catch((error) => {
                                console.log(error)
                                setError('Кол-во сотрудников заполнено. Увеличьте лимит пользователей.')
                            })
                        }}>Добавить</Button>
                    }

                </Form>
            </Modal.Body>
        </Modal>
    </>
}

export default SignEmployee