import React from 'react'
import { Dropdown } from 'react-bootstrap'
import ManageRoles from './ManageRole'
import { IUserList } from '../../../types/user/IUserList'
import DeleteUser from "./DeleteUser";

type Props = {
  user:IUserList,
  update: () => void,
}

const Index = (props: Props) => {
  return <Dropdown>
  <Dropdown.Toggle variant="primary" id="dropdown-basic">
      <i className="fa-solid fa-ellipsis-vertical"></i>
  </Dropdown.Toggle>

  <Dropdown.Menu>
      <ManageRoles user={props.user} update={props.update} />
      <DeleteUser update={props.update} item={props.user}/>
  </Dropdown.Menu>
</Dropdown>
}

export default Index