import React from 'react'
import { Button } from 'react-bootstrap'
import { useStores } from '../../../stores'
import { CRM_URL } from '../../../api'

type Props = {}

const Nav = (props: Props) => {

    const { authStore } = useStores()

    return <nav className='shadow-sm'>
        <div className="nav-container py-3">
            <a href="/">
                <div className="brand">
                    UKI Админ
                </div>
            </a>

            <a href={CRM_URL}>
                    Назад к CRM
            </a>
            
        </div>
    </nav>
}

export default Nav